/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import LoadRecaptcha from "../components/LoadRecaptcha"
import StyledHero from "../components/StyledHero"
import Scael from "../components/Home/Signup/Scael"
import Keys from '../constants/keys'
import {Elements, StripeProvider} from "react-stripe-elements"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

let signup;
if(typeof window !== `undefined`) {
  signup = ({ data, location }) => {
    return (
      <Layout>
      <Helmet>
        <title>Rivet | Signup | SCAEL </title>
      </Helmet>
      <LoadRecaptcha />
      <StyledHero img="none">
      <StripeProvider apiKey={Keys.stripe_publishable_key}><Elements>< Scael location={location} /></Elements></StripeProvider>
      </StyledHero>
      </Layout>
    )
  }
} else {
  signup = ({ data }) => {
    return (
      <Layout>
      <StyledHero img="none">
      This page cannot be rendered on the server
      </StyledHero>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default signup
