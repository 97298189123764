/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/scael.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {injectStripe} from "react-stripe-elements"
import Utils from "../../../utils"
import App from "../../Stripe/App"
import Keys from '../../../constants/keys'
import { navigate } from "gatsby"

class Scael extends React.Component {
  constructor() {
    super();
    this.state = {message: ""}
  }
  render() {
    const handleSubmit = (stripeToken, email) => {
      if(document.getElementById("orgname").value == 0) {
        this.setState({message: "Please provide an organization name"})
        return
      }
      if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        this.setState({message: "Please provide a valid email address"})
        return
      }
      window.grecaptcha.execute(Keys.recaptcha_site_key, {action: 'signup'}).then((recaptchaToken) => {
        return fetch("/api/account", {
          method: "POST",
          body: JSON.stringify({
            "email": email,
            "org": document.getElementById("orgname").value,
            "recaptcha_token": recaptchaToken,
            "plan": "SCAEL",
            "stripe_token": stripeToken.token,
            "invite_code": Utils.getQueryVariable(location, "i"),
            "referral_code": Utils.getQueryVariable(location, "r"),
          }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Utils.getCookie('csrftoken'),
          }
        }).then(function(response) { return response.json() }).then((data) => {
          if(data.ok) {
            navigate(`/activate/?a=${data.activation_id}`);
          } else {
            if(data.error == "bad-invite-code"){
              this.setState({message: "Your invite code is no longer valid"});
            } else if(data.error == "duplicate-user") {
              this.setState({message: "This email address is already in use."});
            } else {
              this.setState({message: "An unknown error has occurred"});
            }
          }
          return
        });
      })
    }
    return (
      <section className={styles.scael}>
      <span className={styles.modalHeader}><Title title="step 2 of 3:" subtitle="Complete this form." /></span>
      <p className={styles.instructionsModal}>Please note: You will not be immediately billed.</p>
      <div className={styles.center}>
      <div className={styles.row3}>
      <input type="input" name="orgname" id="orgname" placeholder="Organization" className={styles.formControl} />
      </div>
      <App handleSubmit={handleSubmit} className={styles.app} />
      <p className={styles.instructions}>{this.state.message}</p>
      <p className={styles.instructions}>Change of plans? <AniLink fade to={"/signup/?i=" + Utils.getQueryVariable(location, "i")}>Take me back!</AniLink></p>
      </div>
      </section>
    )
  }
}
if(typeof window !== `undefined`) {
  Scael = injectStripe(Scael);
} else {
  Scael = () => {
    return (
      <section>This page cannot be server-side rendered</section>
    )
  }

}

export default Scael;
